import React from 'react'
import './style.scss'

const Ngo = () => {
  return (
   <>
   <div className="ngoContainer">
    <div className="ngo">
        <div className="ngoBoxItem">
            <h2>NGO Registration <br/>
            Trust & Society</h2>
            <p>+91 873622985</p>
        </div>

    </div>
   </div>
   </>
  )
}

export default Ngo