import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './style.scss';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';



const EventCardSlider = () => {
 
  return (
    <>

    <div className="eventCardSlide">
  
    </div>
    
    </>
)
}

export default EventCardSlider